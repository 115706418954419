import React from "react";
import styles from "styles/footer.module.css";
import { useRouter } from "next/router";

type Props = {};

function CryptoFooter({}: Props) {
  const router = useRouter();
  return (
    <>
      <div className={styles.space} />
      <div className={styles.footer}>
        <p>
          CryptoSports Pty Ltd. All Rights Reserved, 2023.{" "}
          <a href="https://cryptosports.notion.site/Privacy-Policy-0987bf8197454db281cd34a047c52913">
            Privacy Policy.
          </a>{" "}
          <a href="https://cryptosports.notion.site/Terms-of-Service-a6c6700526e344b08dd8c6094dd56f5a">
            Terms of Service.
          </a>
        </p>
      </div>
    </>
  );
}

export default CryptoFooter;
