import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Link from "next/link";

import styles from "styles/landing.module.css";
import Image from "next/image";
import { useSession } from "next-auth/react";
import IsLoggedOutWrapper from "@/components/IsLoggedOutWrapper";
import CryptoFooter from "@/components/Footer/CryptoFooter";

export default function Home() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // ensure it's in the range 0 -> last value proposition
      setIndex((index + 1) % valuePropositions.length);
    }, 4000);

    return () => {
      clearTimeout(timeout);
    };
  }, [index]);

  return (
    <IsLoggedOutWrapper>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.center}>
            <img
              src="/images/cslogo.svg"
              alt="CryptoSports"
              className={styles.logoSvg}
            />
          </div>
          <div className={styles.imageContainer}>
            <Image
              src={valuePropositions[index].picture}
              layout="fill"
              objectFit="contain"
            />
          </div>
          <div className={styles.valueContainer}>
            <h5 className={styles.title}>{valuePropositions[index].title}</h5>
            <p className={styles.description}>
              {valuePropositions[index].description}
            </p>
          </div>
          <div className={styles.indicatorGroup}>
            {valuePropositions.map((_, i) => (
              <div
                key={`val_prop_${i}`}
                className={clsx(
                  styles.indicatorIndex,
                  i === index ? styles.currentIndex : null
                )}
                onClick={() => setIndex(i)}
              />
            ))}
          </div>
          <p className={styles.instruction}>
            <Link href={"/login"}>
              <span className={styles.link}>Log on</span>
            </Link>
            {" Don't have an account? "}
            <Link href={"/signup"}>
              <span className={styles.link}>Sign up</span>
            </Link>
          </p>
        </div>
      </div>
      <CryptoFooter />
    </IsLoggedOutWrapper>
  );
}

const valuePropositions = [
  {
    title: "Collect cards & build your team",
    picture: "/images/new_comers.png",
    description:
      "Finally, a team that's truly yours to keep—buy, sell, trade your player cards—with verifiable ownership on the blockchain.",
  },
  {
    title: "Compete to win",
    picture: "/images/legends.png",
    description:
      "Play your team against friends or the public for as long (an entire season, a month, or a week) or as short (daily) as you like. ",
  },
  {
    title: "Buy cards low, sell them high",
    picture: "/images/stock.png",
    description:
      "Showcase your eye for talent by buying players before they reach their full potential and cash in on their success.",
  },
  {
    title: "Assess players with Ratings",
    picture: "/images/4th-slide.png",
    description: "100 is the best!",
  },
];
